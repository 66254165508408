import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const style = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
  },
  companyTitle: {
    display: 'flex',
    fontSize: '1rem',
    [theme.breakpoints.only('xs')]: {
      flexFlow: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexFlow: 'row',
    },
  },
  positionTitle: {
    fontSize: '.8rem',
    color: theme.palette.primary.main,
  },
  period: {
    fontSize: '.85rem',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  periodDiff: {
    fontSize: '.7rem',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
  },
  item: {
    display: 'list-item',
    listStyleType: 'disc',
  },
}));

function WorkExperienceItem(props) {
  const { item } = props;
  const { t } = useTranslation();
  const classes = style();

  const formatPeriod = item => {
    let period = moment(item.from).format(t('shortDateFormat'));
    period += ' - ';

    if (item.to) {
      period += moment(item.to).format(t('shortDateFormat'));
    } else {
      period += t('Present');
    }

    return period;
  };

  const formatPeriodDiff = item => {
    let period = '';

    const from = moment(item.from);
    const to = moment(item.to || moment.now());

    // const years = to.diff(from, "years", true);
    const monthsCount = to.diff(from, 'months');

    const months = monthsCount % 12;
    const years = (monthsCount / 12).toFixed(0);
    // console.log("y", y);

    if (years > 0) {
      period += `${years} `;
      period += years > 1 ? t('years') : t('year');
    }

    if (years > 0 && months > 0) {
      period += ' ';
    }

    if (months > 0) {
      period += `${months} `;
      period += months > 1 ? t('months') : t('month');
    }

    return period;
  };

  return (
    <>
      <div className={classes.title}>
        <div color="textPrimary" className={classes.companyTitle}>
          <span>{item.companyName}</span>
          <div>
            <span className={classes.period}>{formatPeriod(item)}</span>
            <span className={classes.periodDiff}>
              ({formatPeriodDiff(item)})
            </span>
          </div>
        </div>
        <div color="textSecondary" className={classes.positionTitle}>
          {item.position}
        </div>
        <p>{item.shortDescription}</p>
      </div>

      {!item.hideJobs && (
        <dl>
          {item.jobs &&
            item.jobs.map(job => (
              <Fragment key={job.from}>
                {job.from && (
                  <dt className={classes.period}>{formatPeriod(job)}</dt>
                )}

                <>
                  {job.jobDescriptions.map(jobDesc => (
                    <dd key={`${job.from}${jobDesc}`} className={classes.item}>
                      {jobDesc}
                    </dd>
                  ))}
                </>
              </Fragment>
            ))}
        </dl>
      )}
    </>
  );
}

WorkExperienceItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default WorkExperienceItem;
