import React, { useEffect, useState } from 'react';

import Education from './Education';
import Helmet from 'react-helmet';
import Skills from './Skills';
import WorkExperienceItem from '../shared/components/WorkExperienceItem';
import i18n from '../i18n';
import { makeStyles } from '@material-ui/core';
// import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-around',
  },
  resumeContainer: {
    [theme.breakpoints.up('md')]: {
      flex: '1 70%',
      paddingRight: '.5rem',
      marginRight: '.5rem',
      borderRight: '1px solid #999',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '1 75%',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 100%',
    },
  },
  leftContainer: {
    display: 'flex',
    flexFlow: 'column',
    [theme.breakpoints.only('md')]: {
      flex: '1 30%',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '1 25%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

function Resume(_props) {
  const { t } = useTranslation();
  const [state, setState] = useState({ workExperiences: [] });
  const classes = styles();

  // console.log("i18n.language", i18n.language);

  useEffect(() => {
    // console.log('useEffect');
    async function fetchData(language) {
      language = language || i18n.language;

      const res = await fetch(`./data/${language}/work-experiences.json`);

      const data = await res.json();
      // console.log("data", language, data);

      setState(state => ({ ...state, workExperiences: data }));
    }

    fetchData();

    i18n.on('languageChanged', language => {
      // console.log('languageChanged');
      fetchData(language);
    });
  }, []);

  return (
    <>
      <Helmet title={t('Work Experience')} />

      <div className={classes.container}>
        <div className={classes.resumeContainer}>
          <h2>{t('Work Experience')}</h2>
          {state.workExperiences.map(item => (
            <WorkExperienceItem item={item} key={item.companyName} />
          ))}
        </div>
        <div className={classes.leftContainer}>
          <Education changeTitle={false} />
          <Skills changeTitle={false} />
        </div>
      </div>
    </>
  );
}

Resume.propTypes = {};

export default Resume;
