import { makeStyles, withStyles } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
      fontSize: ".8rem"
  }
}));

function SkillItem(props) {
  const classes = useStyles();
  const { item } = props;
//   console.log('props', props);
  const [completed, setCompleted] = React.useState(0);
  const itemCompleted = (item && item.completed) || 0;
  const interval = props.interval || 10;
  const height = props.height || 10;
  const borderRadius = props.borderRadius || 10;
  var timer = null;

  const SkillLinearProgress = withStyles({
    root: {
      height: height
    },
    bar: {
      borderRadius: borderRadius,
      backgroundColor: "#6ED262"
    }
  })(LinearProgress);

  const progress = () => {
    setCompleted(oldCompleted => {
      if (oldCompleted === itemCompleted) {
        clearInterval(timer);
      }
      return Math.min(oldCompleted + interval, itemCompleted);
    });
  };

  requestAnimationFrame(() => {
    timer = setInterval(progress, 50);
  });

  return (
    <div className={classes.root}>
      <span className={classes.title}>{item.name}</span>
      <SkillLinearProgress variant="determinate" value={completed} />
    </div>
  );
}

SkillItem.propTypes = {
  item: PropTypes.object.isRequired,
  interval: PropTypes.number,
  height: PropTypes.number,
  borderRadius: PropTypes.number
};

export default SkillItem;
