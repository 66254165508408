import { Box, IconButton, Link } from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneIcon from '@material-ui/icons/Phone';
import PlaceIcon from '@material-ui/icons/Place';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet title={t('Contact')} />

      <h2>{t('Contact')}</h2>

      <Grid container spacing={2}>
        <Grid item>
          <IconButton size="small">
            <PlaceIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" height="100%">
            São Paulo - SP, Brasil
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item>
          <Link
            href="https://www.linkedin.com/in/leandrobarral/?locale=en_US"
            target="_blank"
            rel="noopener"
          >
            <IconButton size="small">
              <LinkedInIcon />
            </IconButton>
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="https://www.linkedin.com/in/leandrobarral/?locale=en_US"
            target="_blank"
            rel="noopener"
          >
            <Box display="flex" alignItems="center" height="100%">
              /leandrobarral
            </Box>
          </Link>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item>
          <Link
            href="mailto:barral@ikecode.com.br"
            target="_blank"
            rel="noopener"
          >
            <IconButton size="small">
              <EmailIcon />
            </IconButton>
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="https://www.linkedin.com/in/leandrobarral/?locale=en_US"
            target="_blank"
            rel="noopener"
          >
            <Box display="flex" alignItems="center" height="100%">
              barral@ikecode.com.br
            </Box>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <Link href="tel:+5511988856996" target="_blank" rel="noopener">
            <IconButton size="small">
              <PhoneIcon />
            </IconButton>
          </Link>
        </Grid>
        <Grid item>
          <Link href="tel:+5511988856996" target="_blank" rel="noopener">
            <Box display="flex" alignItems="center" height="100%">
              +55 11 9.8885-6996
            </Box>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
