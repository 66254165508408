import React from "react";
import PropTypes from "prop-types";

function ElevationScroll(props) {
    const { children } = props;
  
    return React.cloneElement(children, {
      elevation: 2
    });
  }
  
  ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired
  };

  export default ElevationScroll;