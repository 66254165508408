import "./index.css";

import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import DefaultLayout from "./shared/layouts/DefaultLayout";
import { I18nextProvider } from "react-i18next";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import _ from "lodash";
import i18n from "./i18n";
import publicRoutes from "./shared/routes/publicRoutes";

ReactGA.initialize("UA-145442180-1", {
  // debug: true
});
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <Switch>
        {_.map(publicRoutes, (route, key) => {
          const { component, path } = route;
          return (
            <Route
              exact
              path={path}
              key={key}
              render={route => (
                <DefaultLayout component={component} route={route} />
              )}
            />
          );
        })}
      </Switch>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
