import {
  AppBar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  Icon,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import React, { Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/styles";

import LastProjectItemGallery from "./LastProjectItemGallery";
import MuiDialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const style = makeStyles(theme => ({
  card: {
    [theme.breakpoints.only("xs")]: {
      maxWidth: "auto"
    },
    [theme.breakpoints.only("sm")]: {
      maxWidth: "200px"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "250px"
    },
    marginBottom: "2rem"
  },
  cardContent: {
    [theme.breakpoints.only("xs")]: {
      minHeight: "auto"
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "100px"
    }
  },
  appBar: {
    position: "relative"
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 12
  }
}));

function LastProjectItem(props) {
  const { item } = props;
  // console.log("item", item);
  const { t } = useTranslation();
  const classes = style();
  const [galleryModalIsOpen, setGalleryModalIsOpen] = React.useState(false);
  const [readMore, setReadMore] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const DialogContent = withStyles(theme => ({
    root: {
      padding: ".1rem"
    }
  }))(MuiDialogContent);

  const DialogDescriptionContent = withStyles(theme => ({
    root: {
      padding: "2rem"
    }
  }))(MuiDialogContent);

  return (
    <>
      <Card className={classes.card}>
        <CardActionArea onClick={() => setGalleryModalIsOpen(true)}>
          <CardMedia
            component="img"
            image={item.images[0].url}
            height="140"
            alt={item.images[0].description || item.shortDescription}
            title={item.images[0].description || item.shortDescription}
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {item.companyName}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.shortDescription || item.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {item.link && (
            <Button
              component="a"
              href={item.link}
              target="_blank"
              size="small"
              color="primary"
              className={classes.button}
            >
              {t("Visit")}
              <Icon className={clsx(classes.rightIcon, classes.iconSmall)}>
                open_in_new
              </Icon>
            </Button>
          )}

          {(item.description || item.descriptions) && (
            <Button
              size="small"
              color="primary"
              onClick={() => setReadMore(true)}
            >
              {t("Read More")}
            </Button>
          )}
        </CardActions>
      </Card>

      <Dialog
        open={galleryModalIsOpen}
        onClose={() => setGalleryModalIsOpen(false)}
        fullScreen={fullScreen}
        maxWidth={"md"}
      >
        {fullScreen && (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setGalleryModalIsOpen(false)}
                aria-label="close"
              >
                <Icon>close</Icon>
              </IconButton>
            </Toolbar>
          </AppBar>
        )}

        <DialogContent>
          <LastProjectItemGallery images={item.images} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={readMore}
        onClose={() => setReadMore(false)}
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
      >
        {fullScreen && (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setReadMore(false)}
                aria-label="close"
              >
                <Icon>close</Icon>
              </IconButton>
            </Toolbar>
          </AppBar>
        )}

        <DialogDescriptionContent>
          {item.description && <div>{item.description}</div>}

          {item.descriptions &&
            item.descriptions.map((desc, i) => (
              <Fragment key={`${desc.type}${i}`}>
                {item.type === "text" && <Typography>{desc.text}</Typography>}

                {desc.type === "list" && (
                  <div>
                    <span>
                      {desc.text && <Typography>{desc.text}</Typography>}
                    </span>
                    <ul>
                      {desc.items.map(listItem => (
                        <li key={listItem}>{listItem}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </Fragment>
            ))}
        </DialogDescriptionContent>
      </Dialog>
    </>
  );
}

LastProjectItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default LastProjectItem;
