import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEnglish from "./locales/en/translation.json";
import translationPortugues from "./locales/pt-BR/translation.json";

i18n
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translations: translationEnglish
      },
      pt: {
        translations: translationPortugues
      }
    },
    fallbackLng: "en",
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false,

    interpolation: {
      formatSeparator: ","
    },

    react: {
      wait: true
    }
  });

export default i18n;
