import { Button, Icon, IconButton, Toolbar, Tooltip } from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import BottomMenu from './BottomMenu';
import ElevationScroll from './ElevationScroll';
import Grow from '@material-ui/core/Grow';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuSelectLanguage from './MenuSelectLanguage';
import PropTypes from 'prop-types';
import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const styles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuContainer: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
}));

function Header(props) {
  // console.log("props", props);
  const classes = styles();
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    isMenuOpen: false,
  });

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, isMenuOpen: open });
  };

  // console.log("props.themeLightSwitchIconOn", props.themeLightSwitchIconOn);

  const handleLightSwitch = () => {
    // console.log("handleLightSwitch");
    if (props.onThemeLightSwitch) {
      props.onThemeLightSwitch();
    }
  };

  const menuItems = [
    { title: t('Contact'), path: '/contact', icon: 'mail_outline' },
    { title: t('Experience'), path: '/', icon: 'description' },
    {
      title: t('Last Projects'),
      path: '/last-projects',
      icon: 'view_carousel',
    },
    { title: t('Skills'), path: '/skills', icon: 'list_alt', onlyMobile: true },
  ];

  const menuItemsReduced = menuItems.filter(
    ({ path }) => props.location.pathname !== path,
  );

  return (
    <Grow in={true}>
      <ElevationScroll {...props}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography
              component={Link}
              to="/"
              variant="h6"
              color="inherit"
              className={classes.title}
            >
              Leandro Barral
            </Typography>

            <div className={classes.menuContainer}>
              {menuItemsReduced
                .filter(m => !m.onlyMobile)
                .map(({ title, path }) => (
                  <Button component={Link} to={path} color="inherit" key={path}>
                    {title}
                  </Button>
                ))}
            </div>

            <SwipeableDrawer
              anchor="bottom"
              open={state.isMenuOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <div
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  {menuItemsReduced.map(({ title, path, icon }) => (
                    <ListItem button component={Link} to={path} key={path}>
                      <ListItemIcon>
                        <Icon>{icon}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={title} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </SwipeableDrawer>

            <Tooltip title={t('Toggle light/dark theme')}>
              <IconButton onClick={handleLightSwitch}>
                <Icon>lightbulb</Icon>
              </IconButton>
            </Tooltip>

            <MenuSelectLanguage />
          </Toolbar>

          <BottomMenu items={menuItemsReduced} />
        </AppBar>
      </ElevationScroll>
    </Grow>
  );
}

Header.propTypes = {
  onThemeLightSwitch: PropTypes.func,
  themeLightSwitchIconOn: PropTypes.bool,
};

export default withRouter(Header);
