import {
  Container,
  MuiThemeProvider,
  Paper,
  Slide,
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../components/Header";
import Helmet from "react-helmet";
import darkTheme from "../../themes/DarkTheme";
import lightTheme from "../../themes/LightTheme";

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    [theme.breakpoints.only("xs")]: {
      paddingBottom: theme.spacing(6)
    }
  },
  paper: {
    marginTop: "15px",
    marginBottom: "15px",

    [theme.breakpoints.only("xs")]: {
      padding: "15px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5)
    }
  }
}));

function DefaultLayout(props) {
  const classes = styles();
  const RouteComponent = props.component;
  const route = props.route;
  const [state, setState] = useState({
    theme:
      (localStorage.getItem("themeLight") || "dark") === "dark"
        ? darkTheme
        : lightTheme,
    themeLightSwitchIconOn: true
  });
  // console.log("state", state);

  const themeLightSwitch = () => {
    // console.log("state", state);
    const theme = state.theme === darkTheme ? lightTheme : darkTheme;
    const themeLightSwitchIconOn = theme === lightTheme;
    localStorage.setItem("themeLight", theme === lightTheme ? "light" : "dark");
    setState(state => ({ ...state, theme, themeLightSwitchIconOn }));
  };

  return (
    <MuiThemeProvider theme={state.theme}>
      <Helmet titleTemplate="%s | Leandro Barral" />

      <CssBaseline />

      <div className={classes.root}>
        <Header
          onThemeLightSwitch={themeLightSwitch}
          themeLightSwitchIconOn={state.themeLightSwitchIconOn}
        />

        <Container className={classes.container}>
          <Slide direction="right" in={true}>
            <Paper className={classes.paper} elevation={2}>
              <RouteComponent route={route} />
            </Paper>
          </Slide>
        </Container>
      </div>
    </MuiThemeProvider>
  );
}

DefaultLayout.propTypes = {};

export default DefaultLayout;
