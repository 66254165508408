import React, { useEffect, useState } from 'react';

import { Chip } from '@material-ui/core';
import Helmet from 'react-helmet';
import SkillItem from '../shared/components/SkillItem';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around',
  },
  skillItem: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    flex: '1 100%',
    paddingBottom: '.5rem',
  },
  tag: {
    marginBottom: '.2rem',
  },
}));

const Skills = props => {
  const { changeTitle = true } = props;
  const { t } = useTranslation();
  const [skills, setSkills] = useState([]);
  const [keywords] = useState('');
  const classes = styles();

  useEffect(() => {
    const abortController = new AbortController();
    async function fetchData() {
      const fetchSkills = fetch(`./data/skills.json`, {
        signal: abortController.signal,
      });

      const res = await fetchSkills;

      const data = await res.json();
      // console.log("data", data);
      setSkills(data);
    }

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className={classes.container}>
      {changeTitle && (
        <Helmet title={t('Skills')}>
          <meta name="keywords" content={keywords} />
        </Helmet>
      )}

      <h2>{t('Skills')}</h2>

      {skills.map(skill => (
        <div key={skill.name} className={classes.skillItem}>
          {skill.type === 'Progress' && (
            <SkillItem item={skill} height={12} borderRadius={0} />
          )}

          {skill.type === 'Tags' &&
            skill.tags.map(tag => (
              <Chip
                key={tag.name}
                label={tag.name}
                className={classes.tag}
                variant={tag.variant || 'default'}
                color={tag.color || 'default'}
                size={tag.size || 'small'}
              />
            ))}
        </div>
      ))}
    </div>
  );
};

export default Skills;
