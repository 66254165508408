import React, { useEffect, useState } from 'react';

import Helmet from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around',
  },
  skillItem: {
    flex: '1 100%',
    paddingBottom: '.5rem',
  },
}));

const Education = (props) => {
  const { changeTitle = true } = props;
  const { t } = useTranslation();
  const [educations, setEducation] = useState([]);
  const classes = styles();

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchData() {
      const fetchEducation = fetch(`./data/education.json`, {
        signal: abortController.signal,
      });

      const res = await fetchEducation;

      const data = await res.json();
      // console.log("data", data);
      setEducation(data);
    }

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className={classes.container}>
      {changeTitle && <Helmet title={t('Graduation')} />}

      <h2>{t('Graduation')}</h2>

      {educations.map(education => (
        <div key={education.graduation}>
          <h3 style={{ margin: 0 }}>{education.institution}</h3>
          <div>{education.graduation}</div>
          <div>
            {moment(education.from).format('YYYY')}
            {' - '}
            {moment(education.to).format('YYYY')}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Education;
