import "react-image-gallery/styles/css/image-gallery.css";
import './LastProjectItemGallery.css'

import ImageGallery from "react-image-gallery";
import PropTypes from "prop-types";
import React from "react";

function LastProjectItemGallery(props) {
  const { images } = props;

  const imagesGallery = images.map(image => {
    return {
      original: image.url,
      thumbnail: image.url,
      description: image.description,
      originalClass: "galleryImage"
    };
  });

  return (
    <ImageGallery
      items={imagesGallery}
      showThumbnails={false}
      showFullscreenButton={false}
      autoPlay={true}
      disableArrowKeys={imagesGallery.length === 0}
      showPlayButton={imagesGallery.length > 1}
      useBrowserFullscreen={false}
    />
  );
}

LastProjectItemGallery.propTypes = {
  images: PropTypes.array.isRequired
};

export default LastProjectItemGallery;
