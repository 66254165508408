import React from "react";
// import PropTypes from "prop-types";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

function MenuSelectLanguage(props) {
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function changeLanguage(lang) {
    setAnchorEl(null);
    if (lang) {
      i18n.changeLanguage(lang);
    }
    // console.log("lang", lang);
  }

  return (
    <div>
      <Tooltip title={t("Languages")}>
        <IconButton
          aria-label="Language Selector"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <Icon>language</Icon>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        onClose={() => changeLanguage()}
      >
        <MenuItem onClick={() => changeLanguage("en")}>
          <ListItemIcon>
            <ReactCountryFlag code="us" />
          </ListItemIcon>
          English
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("pt-BR")}>
          <ListItemIcon>
            <ReactCountryFlag code="br" />
          </ListItemIcon>
          Português
        </MenuItem>
      </Menu>
    </div>
  );
}

MenuSelectLanguage.propTypes = {};

export default MenuSelectLanguage;
