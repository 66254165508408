import Contact from "../../pages/Contact";
import LastProjects from "../../pages/LastProjects";
import Resume from "../../pages/Resume";
import Skills from "../../pages/Skills";

export default {
  Home: {
    component: Resume,
    path: "/"
  },
  Contact: {
    component: Contact,
    path: "/contact"
  },
  Skills: {
    component: Skills,
    path: "/skills"
  },
  LastProjects: {
    component: LastProjects,
    path: "/last-projects"
  }
};
