import "./LastProjects.css";

import React, { useEffect, useState } from "react";

import { CSSTransitionGroup } from "react-transition-group"; // ES6
import Helmet from "react-helmet";
import LastProjectItem from "../shared/components/LastProjectItem";
import i18n from "../i18n";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const styles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center"
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between"
    }
  }
}));

function LastProjects(props) {
  const { t } = useTranslation();
  const [state, setState] = useState({ lastProjects: [] });
  const classes = styles();

  // console.log("i18n.language", i18n.language);

  useEffect(() => {
    // console.log('useEffect');

    const abortController = new AbortController();
    const signal = abortController.signal;

    async function fetchData(language) {
      language = language || i18n.language;

      const fetchLastProjects = fetch(`./data/${language}/last-projects.json`, {
        signal: signal
      });

      const res = await fetchLastProjects;

      const data = await res.json();
      // console.log("data", language, res, data);

      setState(state => ({ ...state, lastProjects: data }));
    }

    fetchData();

    i18n.on("languageChanged", language => {
      // console.log('languageChanged');
      fetchData(language);
    });

    return () => {
      i18n.off("languageChanged");
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Helmet title={t("Last Projects")} />

      <h2>{t("Last Projects")}</h2>

      <CSSTransitionGroup
        className={classes.container}
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {state.lastProjects.map((item, index) => (
          <LastProjectItem item={item} key={item.projectName} />
        ))}
      </CSSTransitionGroup>
    </>
  );
}

LastProjects.propTypes = {};

export default LastProjects;
