import React from "react";
import { Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

const useStyles = makeStyles(theme => ({
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 1
  },
  bottomNav: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
}));

export default function BottomMenu(props) {
  const classes = useStyles();
  const { items } = props;
  const stickToBottom =
    props.stickToBottom === undefined || props.stickToBottom === null
      ? true
      : props.stickToBottom;

  return (
    <div className={classes.bottomNav}>
      <BottomNavigation
        showLabels
        className={stickToBottom && classes.stickToBottom}
      >
        {items.map(({ title, path, icon }) => (
          <BottomNavigationAction
            label={title}
            component={Link}
            to={path}
            key={path}
            icon={<Icon>{icon}</Icon>}
          />
        ))}
      </BottomNavigation>
    </div>
  );
}
